@import '../../assets/scss/partials/vars';

.form-container {
    display: block;
    width: 100%;
    margin: 20px auto 40px;

    @media(min-width: $screen-md){
        margin: 70px auto 65px;
    }

    form.subscribe-mailchimp {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: $baseFont;
        font-size: 0.75rem;
        color: $bg-website;

        //border: 2px solid $bg-website;
           // border-radius: 10px 0 0 10px;

        @media(min-width: $screen-md){
            font-size: 0.82rem;
            justify-content: space-between;
            flex-direction: row;
        }

        div.email-wrap {
            display: flex;
        }

        input.email {
            box-sizing: border-box;
            height: 38px;
            width: 200px;
            padding: 2px 9px 0px;
            background: $upside-red;
            border: 2px solid $bg-website;
            border-radius: 10px 0 0 10px;
            outline: none;
            color: $bg-website;
            line-height: 1;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            @media(min-width: $screen-md){
                width: 160px;
                margin-left: 12px;
            }
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $bg-website;
            opacity: 1; /* Firefox */
        }
          
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $bg-website;
        }
          
          
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: $bg-website;
        }

        .radio-wrap {
            display: flex;
            width: 290px;
            text-align: left;
            margin: 16px auto 34px;
            padding: 0px 10px;

            @media(min-width: $screen-md){
                width: auto;
                margin: 0;
            }
        }

        label.radio-label {
            text-align: left;
            font-size: 0.8rem;
            line-height: 1.2;

            @media(min-width: $screen-md){
                line-height: 1.2;
            }  
        }

        button.submit {
            box-sizing: border-box;
            width: 90px;
            height: 38px;
            padding: 3px 0 0px 0;
            background: $bg-website;
            border: 2px solid $bg-website;
            border-radius: 0 10px 10px 0;
            color: $upside-red;
            font-family: $baseFont;
            //font-size: 0.85rem;
            cursor: pointer;
            -webkit-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }



        
    }

    .msg-alert {
        display: block;
        position: absolute;
        top: 255px;
        width: 100%;

        @media(min-width: $screen-md){
            top: 125px;
        }  
        
        p {
            margin: 0 auto;
            color: $white !important;
            font-size: 0.95rem;
            text-align: center;

        }
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        //color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: $upside-red;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}