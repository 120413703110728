@import '../../assets/scss/partials/vars';

.footer {
    display: block;
    //margin: 0 0 30px;
    padding: 0 0 30px;
    
    @media(min-width: $screen-md){
        padding: 0 0 10px;
    }

}
.blockwrap.footer {
    display: flex;
    flex-direction: column;
    background: $upside-red;
    color: $bg-website;

    @media(min-width: $screen-md){
        flex-direction: column;
    }

    .container {
        display: flex;
        flex-direction: column;
        
        @media(min-width: $screen-md){
            flex-direction: row;
            align-items: center;
            justify-content: center;
            max-width: 858px;
        }

        p {
            display: block;
           // width: 275px;
            margin: 50px auto 20px;
            font-size: 0.8rem;
            text-align: left;
           
            @media(min-width: $screen-md){
                width: 300px;
                margin: 0;
                font-size: 0.8rem;

            }
        }
    }
}