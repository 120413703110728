@import '../../assets/scss/partials/vars';

.home {
    display: block;

    .blockwrap.bg-wrap {
        max-width: 89%;
        background: $white;
        margin: 35px auto 0px auto;
        padding-bottom: 90px;
        box-shadow: 6px 6px 4px -2px rgba(221, 213, 205, 0.37), -6px 6px 4px -2px rgba(221, 213, 205, 0.37);

        @media(min-width: $screen-md){
            max-width: 100%;
            margin: 0 auto;
            padding-bottom: 90px;

        }
    }

    .container {

        @media(min-width: $screen-md){
            max-width: 85%;
        }
        @media(min-width: $screen-lg){
            max-width: 630px;
        }

    }

    .content {
        padding: 0 25px;
        
        @media(min-width: $screen-md){
            padding: 0 20px;
        }
    }

    a.logo{
        display: block;
        height: 100%;
        width: 100%;
        max-width: 60px;
        margin: 40px auto 0;

        @media(min-width: $screen-md){
            max-width: 95px;
            margin: 65px auto 0;
        }
        
        img { 
            display: block;
            width: 100%;
        }
    }

    h1 {
        display: block;
        margin-top: 42px;
        margin-bottom: 45px;
        font-family: $headingFont;
        font-size: 9vw;
        font-weight: 500;
        text-align: left;

        @media(min-width: $screen-xs){
            font-size: 10vw;
        }

        @media(min-width: $screen-md){
            margin-top: 65px;
            margin-bottom: 75px;
            font-size: 4.6rem;
            line-height: 1;
        }
        @media(min-width: $screen-lg){
            font-size: 5.2rem;
        }
    }

    p {
        margin: 18px auto;
        line-height: 1.4;
        font-family: $baseFont;
        font-size: 4vw;
        text-align: left;

        &.txt-center{
            text-align: center;
        }

        @media(min-width: $screen-sm){
            font-size: 0.82rem;
        }
        @media(min-width: $screen-md){
            margin: 24px auto;
            font-size: 0.82rem;
            line-height: 1.7;
           
        }
        @media(min-width: $screen-lg){
            margin: 30px auto;
            font-size: 0.82rem;
        }
    }

    img.ceo-signature {
        display: block;
        max-width: 125px;
        margin: 0 auto 30px;

        @media(min-width: $screen-md){
            margin: 0 auto 50px;
        }
    }
}

.blockwrap.logo {
    margin: 0 auto 40px;

    @media(min-width: $screen-md){
        margin: 0 auto 0px;
    }

    a {
        max-width: 50px;
    }
}