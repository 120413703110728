@import '../../../assets/scss/partials/vars';

.btn-social {
    height: 20px;
    fill: $upside-red;
    
    &:hover {
        fill: #fc7d71;
        transition: all 0.35s linear;
    }
}

.footer {
    .social-buttons {
        .btn-social {
            height: 20px;
            fill: $bg-website;
            
            &:hover {
                fill: #f9d0cc;
                transition: all 0.35s linear;
            }
        }
    }
}