@import '../../assets/scss/partials/vars';

.wrapper {
    display: block;
    //margin: 35px 30px 58px 30px;
//    box-shadow: 6px 6px 4px -2px rgba(221, 213, 205, 0.37), -6px 6px 4px -2px rgba(221, 213, 205, 0.37);
   //box-shadow: 0 8px 6px -2px rgba(221, 213, 205, 0.97);

    @media(min-width: $screen-md){
        max-width: 700px;
        margin: 0px auto 90px;
    }
    @media(min-width: $screen-lg){
        //max-width: 780px;
        max-width: 858px;
        margin: 0px auto 75px;
    }
}