@import '../../assets/scss/partials/vars';

.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;

    h1 {
        text-align: center;
        font-size: 10vw;
        @media(min-width:$screen-md) {
            font-size: 5rem;
        }
    }
}