@import '../../assets/scss/partials/vars';

.signature-wrap {
   
    display: block;
    max-width: 125px;
    margin: 0 auto 30px;
    fill: none;

    @media(min-width: $screen-md){
        margin: 0 auto 50px;
    }
  
}
.ceo-signature {
    
    fill: $black;
}