.sign-form {
    width: 300px;
    padding-bottom: 20px;

    input {
        &:focus, &:focus-visible {
            outline: none;
        }
    }
    
    button {
        &:hover {
            background-color:hsl(190, 83%, 64%)
        }
    }
    
    input, button {
        box-sizing: border-box;
        padding: 10px;
        margin: 5px;
        border-radius: 1rem;
        border:none;

        
    }
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.form-error-list {
    font-size: 0.8rem;
    color: hsl(0, 83%, 34%);
    background-color: hsl(0,100%,100%);
    border-radius: 1em;
    margin: 5px;
    padding: 5px 10px;
    box-sizing: content-box;
    text-align: left;

    p {
        padding: 3px 0px;
    }
    p::before {
        display: inline;
        content: "⚠ ";
    }
}