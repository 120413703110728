@import '../../assets/scss/partials/vars';

.header {
    display: block;
    margin: 25px auto 105px;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 100%;

        @media(min-width: $screen-md){
           padding: 0 0px;
        }

        @media(min-width: $screen-xl){
            max-width: 100%;
        }
    }

    a.logo{
        display: block;
        height: 100%;
        width: 100%;
        max-width: 60px;

        @media(min-width: $screen-md){
            max-width: 50px;
            margin-left: 8px;
        }

        img { 
            display: block;
            width: 100%;
        }
    }
}